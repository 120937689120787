import React from 'react';
import css from './SectionFooter.module.css';

const NewsletterSubscribe = () => {
  return (
    <div className={css.newsletterForm}>
      <iframe
        src="https://themanecut.substack.com/embed"
        width="100%"
        height="320px"
        style={{ border: '1px solid #EEE', background: '#e25e38' }}
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default NewsletterSubscribe;
